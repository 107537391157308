










import { Component, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'

@Component({
  components: {
    ModalBase,
    ButtonBase,
    InputWithLabel,
  },
})
export default class ModalAdminCustomerClassroomManagerAdd extends Mixins(ModalBaseMethod) {
  private branchId = 0

  private nickname = ''

  private isProcessing = false

  /**
   * バリデーションエラー
   */
  private get nameErrors(): string[] {
    const errors = []
    if (this.nickname !== '') {
      if (this.nickname.length > 20) {
        errors.push('教室長氏名は20文字までです')
      }
    }
    return errors
  }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    return this.nickname.length > 0 && this.nameErrors.length === 0 ? 'blue' : 'pointer-events-none'
  }

  public async showAddManager(branchId: number) {
    this.branchId = branchId
    this.isProcessing = false
    this.nickname = ''
    this.show()
  }

  /**
   * 教室長を追加するメソッド
   */
  private async createManager() {
    if (this.isProcessing) return
    this.isProcessing = true
    await Vue.prototype.$http.httpWithToken
      .post('/users/branches', { branchId: this.branchId, nickname: this.nickname })
      .catch((error: any) => {
        if (error.response.data.status === 422) {
          alert('教室長が最大人数まで登録されています。ページを更新してお確かめください。')
        }
      })
      .finally(() => {
        this.$emit('hide-model')
        this.hide()
      })
  }
}
